<template>
  <div class="body" v-if="order">
    <div class="header">
      <div class="">
        <b-img class="logo" :src="require(`@/assets/images/logo_2.svg`)"/>
      </div>
      <div class="div-border">
        <h4 class="font-black">TVS Approved</h4>
        <h5 class="number-order"> # {{ order.orderId }}</h5>
      </div>
    </div>
    <div class="section_1">
      <div><h2 class="title font-bold">Certificate of Authenticity</h2></div>
      <div><h6 class="m-0 font-black">TVS declares that for the following vehicle TVS software enhancements have been performed
        successfully.</h6></div>
    </div>
    <div class="section_2">
      <div>
        <h5 class="font-black"><span class="font-bold">MAKE:</span> {{ order.vehicle.brand }}</h5>
      </div>
      <div>
        <h5 class="font-black"><span class="font-bold">MODEL:</span> {{ order.vehicle.model }}</h5>
      </div>
      <div>
        <h5 class="font-black"> <span class="font-bold">VIN:</span> {{ order.VIN }}</h5>
      </div>
    </div>
    <div class="section_3">
      <div class="section_3_left">
        <div class="information">
          <h5 class="mr-3 font-black">The services were carried out by: <span class="font-bold">{{ order.dealerId }}</span></h5>
          <h5 class="font-weight-normal font-black">on date: <span class="font-bold">{{ order.createdAt | localeDate }}</span></h5>
        </div>
        <div class="attention">
          <h6 class="font-bold">Attention:</h6>
        </div>
        <div class="font-black">
          <h6 class="font-black" style="font-style: italic">
            Please note that further vehicle modifications might compromise the <br>
            validity of this certificate and the already installed TVS products or services. <br>
            Contact your reseller or TVS directly for guidance in case of any doubt.
          </h6></div>
      </div>
      <div class="section_3_right">
          <h6 class="qr qr_margin font-black">REVIEW ORDER DETAILS</h6>
          <div class="qr" ref="qrcode"></div>
      </div>
    </div>
    <div class="footer">
      <h3 class="number-order">Enjoy the TVS experience!</h3>
    </div>
  </div>
</template>

<script>
import * as QRCode from 'easyqrcodejs';
import {get} from '@/services/api';

export default {
  name: 'CertSoftwareTunning',
  data: () => ({
    id: null,
    url: `${window.location.protocol}//${window.location.host}`,
    order: null
  }),
  methods: {
    load() {
      get(`site/order/${this.id}`)
        .then(({data}) => {
          if(data){
            this.order = {
              ...data,
              vehicle: JSON.parse(data.vehicle),
              description: JSON.parse(data.description)
            };
            this.$nextTick(() => this.createCode());
          }
        });
    },
    createCode() {
      new QRCode(this.$refs.qrcode, {
        text: `${this.url}/admin/detail-order/${this.order.orderId}`,
        logo: `${this.url}/admin/assets/images/logo.svg`,
        logoBackgroundTransparent: true,
        width: 200,
        height: 200,
      });
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.load();
  },

};
</script>

<style scoped lang="scss">
.body {
  width: 100%;
  padding: 4vw;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    width: 100%;
  }

  .div-border {
    border-radius: 25px;
    border: 3px solid #c14242;
    width: 15vw;
    height: 5vw;
    justify-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .number-order {
    color: #c14242 !important;
    text-align: center;
    font-weight: bold;
  }

  .section_1 {
    margin-top: 4vw;
  }

  .section_2 {
    margin-top: 2vw;
  }

  .section_3 {
    margin-top: 2vw;
    display: flex;

    &_left {
      width: 50%;
    }

    &_right {
      width: 50%;
    }
  }

  .qr {
    text-align: end;
  }

  .information {

  }

  .attention {
    margin-top: 4vw;
  }

  .title {
    width: fit-content;
    padding-bottom: .5vw;
    border-bottom: 4px solid #c14242;
  }

  .footer {
    text-align: center;
  }
  .qr_margin{
    margin-right: 2%;
  }
  .font-bold{
    font-weight: bold;
    color: black;
  }
  .font-black{
    color: black !important;
  }
}

@media print {
  .logo {
    width: 400px !important;
  }
  .section_2 {
    margin-top: 5vw !important;
  }
  .attention {
    margin-top: 5vw !important;
  }
  .section_3_left {
    width: 65% !important;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 2vw;
    width: 100%;
    text-align: center;
  }
  .div-border {
    padding-top: 1vw;
    width: 22vw !important;
    height: 8vw !important;
  }
  @page {
    size: landscape;
    page-break-after: always;
    padding: 0;
    margin: 0;
  }
}
</style>
